import React from "react"
import Layout from "../../components/layoutEn"
import SEO from "../../components/layout/seo"

import MainImg from "../../img/noLimitImg.jpg"

function Bems() {
  return (
    <Layout>
      <SEO title="M2MTech" />
      <div className="fobWrap bemsWrap">
        <p className="fobMainImg escalMainImg">
          <img src={MainImg} alt="" />
        </p>
        <div className="sectionWrap fobSectionWrap bemsOverview">
          <div className="textWrap">
            <h2>Building Energy Management System(BEMS)</h2>
            <p className="bodyText">
              It is an integrated management system that installs sensors and
              measurement equipment in facilities (lighting, heating,
              ventilation, and socket-outlets) and links them to
              telecommunication networks to monitor detailed usage by energy
              source and usage in real time, analyzes collected energy usage
              information through S/W, and optimizes operation, reduces costs,
              and saves energy through automatic control of facilities. <br />
              <br />
              The government has promoted the rationalization of energy use
              under the leadership of the government since '17.1.1', which was
              centered on simple monitoring of various equipment and automatic
              or manual control, and manual control. In the new era of dry
              season due to the enlargement of commercial buildings and
              reconstruction of old buildings, ELSA Platform provides various
              functions necessary for energy management and efficient
              monitoring, and effectively reduces maintenance costs (average
              71%) required to operate buildings that are long-term assets that
              are used for more than 30 years after design and construction.
            </p>
          </div>
        </div>
        <div className="sectionWrap fobSectionWrap bemsBreakdown">
          <div className="textWrap">
            <h2>BEMS functionalities of Elsa platform</h2>
          </div>
          <ul>
            <li>
              <p className="title">Energy Diagnostic Management</p>
              <p className="element">
                Energy waste diagnosis
                <br />
                Equipment operation status diagnosis
                <br />
                Facility performance improvement plan
                <br />
                Improve energy waste
                <br />
                Decision support
              </p>
            </li>
            <li>
              <p className="title">Energy monitoring</p>
              <p className="element">
                Energy monitoring
                <br />
                Facility monitoring
                <br />
                Environmental monitoring
              </p>
            </li>
            <li>
              <p className="title">Statistical analysis</p>
              <p className="element">
                Energy trend analysis
                <br />
                Facility performance analysis
                <br />
                Energy comparison analysis
                <br />
                Energy statistical analysis
              </p>
            </li>
            <li>
              <p className="title">Forecast/Optimization Management</p>
              <p className="element">
                Energy use prediction
                <br />
                Energy use optimization
              </p>
            </li>
            <li>
              <p className="title">Smart operation</p>
              <p className="element">
                Sustainability Report
                <br />
                Operation improvement plan
              </p>
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  )
}
export default Bems
